import { serverLink } from '../store/constants'
import { message, loading } from '../components/Message/message'

export const networkRequest = async url => {
  loading.show()
  try {
    const res = await (await fetch(`${serverLink}/${url}`)).json()
    loading.close()
    if (res.code === 0) {
      return res
    } else {
      message.popup(res.msg)
    }
  } catch (e) {
    loading.close()
    message.popup(e.message)
  }
}
