import React from 'react'
import { useDerivedMintInfo } from '../../state/mint/hooks'

export default function AuraUsdtPrice({ tokenAura, tokenUsdt }) {
  const { price } = useDerivedMintInfo(tokenAura ?? undefined, tokenUsdt ?? undefined)
  return (
    <>
      <span>{price?.toSignificant(4) || '-'}</span>
    </>
  )
}
