import React, { useState, useEffect, useRef } from 'react'
import { serverLink } from '../../store/constants'
import './acrossChain.css'
import AppBody from '../AppBody'
import { getUserAddress, web3, hjwExtract } from '../../utils/playerAddr'
import Spin from '../Bank/Spin'
import intl from 'react-intl-universal'
import HJWLogo from '../../assets/images/acrosschain/HJW.png'
const RecordHJW = props => {
  const [data, setData] = useState({})
  const [currentChainId, setCurrentChainId] = useState('')
  const [userAddr, setUserAddr] = useState('')
  const [flagError, setFlagError] = useState(false)
  const [countdown, setCountdown] = useState(2)
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [flag, setFlag] = useState(false) //是否可提取，bsc可提取，heco不可提取
  const time = useRef(null)
  //倒计时
  const countdownFun = () => {
    setCountdown(2)
    time.current = setInterval(() => {
      setCountdown(countdown => countdown - 1)
    }, 1000)
  }
  const message = msg => {
    setLoading(false)
    setFlagError(true)
    setMessageError(msg)
    countdownFun()
  }
  //获取数据,r当前链id
  const getData = async (address, r) => {
    setLoading(true)
    try {
      const res = await (
        await fetch(`${serverLink}/api/token-release/get-base-info/${address}
      `)
      ).json()
      if (res.code === 0) {
        setLoading(false)
        setData(res.data.info)
        if (r === res.data.withdraw_chain_id) {
          setFlag(true)
        }
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }
  //提取
  const getExtract = async () => {
    if (flag) {
      setLoading(true)
      try {
        const res = await (
          await fetch(`${serverLink}/api/token-release/do-withdraw/${userAddr}
      `)
        ).json()
        if (res.code === 0) {
          await hjwExtract(
            res.data.withdraw_contract_address,
            res.data.historicalWithdrawalNumber,
            res.data.tokenAddress,
            res.data.amount,
            res.data.deadline,
            res.data.v,
            res.data.r,
            res.data.s,
            userAddr
          )
          setLoading(false)
          message(intl.get('withdrawalS'))
          getData(userAddr, currentChainId)
        } else {
          message(res.msg)
        }
      } catch (e) {
        message(e.message)
      }
    } else {
      message(intl.get('goToBscEx'))
    }
  }
  useEffect(() => {
    if (countdown < 0) {
      setFlagError(false)
      setMessageError('')
      clearInterval(time.current)
    }
  }, [countdown])
  useEffect(() => {
    web3()
      .eth.net.getId()
      .then(r => {
        setCurrentChainId(r)
        getUserAddress().then(addr => {
          setUserAddr(addr)
          getData(addr, r)
        })
      })
  }, [])
  return (
    <>
      {loading ? (
        <div className="spin-bg">
          <Spin />
        </div>
      ) : null}
      <AppBody></AppBody>
      <div className="rh-main">
        <div className="partAura">
          <div className="extract">
            <img alt="logo" src={HJWLogo}></img>
            <div onClick={() => getExtract()}>{intl.get('withdrawal')}</div>
          </div>
          <div className="income">{intl.get('totalAmountE')}</div>
          <div className="incomeData">{data.wait_withdraw_number}</div>
        </div>
        <div className="rh-record">
          <div className="rh-record-l">
            <div>{intl.get('totalCV')}</div>
            <div>{data.sum_total_amount}</div>
          </div>
          <div className="rh-record-m">|</div>
          <div className="rh-record-l">
            <div>{intl.get('toBeReleased')}</div>
            <div>{data.sum_use_amount}</div>
          </div>
        </div>
      </div>
      <div className="modal" style={{ display: flagError ? 'flex' : 'none' }}>
        <div className="modalContent ac-modalContent">
          <div style={{ color: 'red', fontSize: 12 }}>{messageError}</div>
        </div>
      </div>
    </>
  )
}

export default RecordHJW
