import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import intl from 'react-intl-universal'

const PoolHeaderFrame = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: #1b1a1e;
  height: 56px;
  padding-top: 26px;
  font-weight: bold;
  font-size: 13px;
  z-index: 3;
`

const PoolHeaderElement = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    justify-content: start;
  `};
`
const Link = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  margin-right: 18px;
`
const LinkLine = styled.div`
  width: 65px;
  height: 3px;
  margin-top: 8px;
`

export default function PoolHeader({ url }) {
  const [menuList, setMenuList] = useState([
    { title: intl.get('liquidityMining'), href: '#/bankIncomeList', flag: false },
    { title: intl.get('addingLiquidity'), href: '#/add', flag: false },
    { title: intl.get('capitalPool'), href: '#/pool', flag: false }
  ])

  useEffect(() => {
    let list = menuList
    list.forEach(item => {
      item.flag = false
      if (url.search(item.href) !== -1) {
        item.flag = true
      }
    })
    setMenuList(list)
  }, [])
  return (
    <PoolHeaderFrame>
      <PoolHeaderElement>
        {menuList.map((item, index) => (
          <Link key={index} href={item.href}>
            <div style={{ color: item.flag ? '#ffffff' : '#999999' }}>{item.title}</div>
            <LinkLine
              style={{ backgroundImage: item.flag ? 'linear-gradient(to right, #fd81e8, #f84da5)' : 'none' }}
            ></LinkLine>
          </Link>
        ))}
      </PoolHeaderElement>
    </PoolHeaderFrame>
  )
}
