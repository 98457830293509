import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import homeLogo from '../../assets/images/homeLogo.png'
import LanguageIcon from '../../assets/images/language.png'
import AuraPriceIcon from '../../assets/images/auraPriceIcon.png'
import LanguageSelect from '../../assets/images/languageSelect.png'
import intl from 'react-intl-universal'
import Settings from '../Settings'
import { useAllTokens } from '../../hooks/Tokens'
import { web3 } from '../../utils/playerAddr'
import AuraUsdtPrice from './auraUsdtPrice'

const HeaderFrame = styled.div`
  width: 100%;
  top: 0;
  position: absolute;
  background: #1b1a1e;
`

const HeaderElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 54px;
`
const Logo = styled.img`
  height: auto;
  width: 50px;
`
const HeaderRight = styled.div`
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const LanguageImg = styled.img`
  height: 30px;
  width: 30px;
  margin-right: 8px;
`
const AuraPriceImg = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 4px;
`
const HeaderAuraPrice = styled.div`
  font-size: 12px;
  color: #b3b3b3;
  display: flex;
  align-items: center;
  background-color: #333333;
  border-radius: 4px;
  padding: 0 6px;
  margin-left: 8px;
`
const Language = styled.div`
  width: 100vw;
  height: 682px;
  position: absolute;
  background-color: rgba(220, 38, 38, 0);
  z-index: 100;
  top: 58px;
  display: flex;
  justify-content: flex-end;
`
const LanguageList = styled.div`
  padding: 16px;
  border: 1px solid #485053;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  background-color: #1b1a1e;
  width: 150px;
  height: 245px;
  margin-right: 30px;
`
const LanguageItem = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LanguageSelectImg = styled.img`
  height: 10px;
  width: 15px;
`

export default function HomeHeader() {
  const [menuFlag, setMenuFlag] = useState(false)
  const [tokenUsdt, setTokenUsdt] = useState({})
  const [tokenAura, setTokenAura] = useState({})

  const languageList = [
    { title: 'English', flag: false, url: 'en' },
    { title: '日語', flag: false, url: 'japanese' },
    { title: '한국어', flag: false, url: 'korean' },
    { title: '繁體中文', flag: false, url: 'tw' },
    { title: '简体中文', flag: false, url: 'ch' }
  ]
  const showMenu = () => {
    setMenuFlag(!menuFlag)
  }
  const hideMenu = () => {
    setMenuFlag(false)
  }
  const languageChange = (language: any) => {
    localStorage.setItem('localee', language)
    window.location.reload(true)
  }
  const allTokens = Object.values(useAllTokens())

  // let tokenUsdt = { address:'', decimals:0, chainId:0 }
  // let tokenAura = { address:'', decimals:0, chainId:0 }

  const getPrice = async () => {
    const id = await web3().eth.net.getId()
    allTokens.map(item => {
      if (item.chainId === id && item.symbol === 'USDT') {
        setTokenUsdt(item)
      }
      if (item.chainId === id && item.symbol === 'AURA') {
        setTokenAura(item)
      }
    })
  }
  useEffect(() => {
    getPrice()
  }, [])
  return (
    <>
      <HeaderFrame>
        <HeaderElement>
          <Logo src={homeLogo} />
          <HeaderRight>
            <LanguageImg src={LanguageIcon} onClick={() => showMenu()} />
            <Settings />
            <HeaderAuraPrice>
              <AuraPriceImg src={AuraPriceIcon} />
              <span>
                <span>$</span>
                <AuraUsdtPrice tokenAura={tokenAura} tokenUsdt={tokenUsdt} />
              </span>
            </HeaderAuraPrice>
          </HeaderRight>
        </HeaderElement>
      </HeaderFrame>
      <Language
        style={{ display: menuFlag ? 'flex' : 'none' }}
        onClick={() => {
          hideMenu()
        }}
      >
        <LanguageList>
          <div>{intl.get('languageSettings')}</div>
          {languageList.map((item, index) => (
            <LanguageItem key={index} onClick={() => languageChange(item.url)}>
              <span style={{ color: localStorage.getItem('localee') === item.url ? '#ffffff' : '#B3B3B3' }}>
                {item.title}
              </span>
              <LanguageSelectImg
                src={LanguageSelect}
                alt=""
                style={{ display: localStorage.getItem('localee') === item.url ? 'block' : 'none' }}
              />
            </LanguageItem>
          ))}
        </LanguageList>
      </Language>
    </>
  )
}
