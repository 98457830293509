import React, { useState, useEffect } from 'react'
import AppBody from '../AppBody'
import './bank.css'
import line from './img/line.png'
import select from './img/select.png'
import noSelect from './img/noSelect.png'
import { getUserAddress } from '../../utils/playerAddr'
import { networkRequest } from '../../utils/networkRequest'
import intl from 'react-intl-universal'
import Footer from '../../components/Foot/foot'
import PoolHeader from '../../components/Header/poolHeader'
import { useAllTokens } from '../../hooks/Tokens'

let closeTimer
const BankIncomeList = props => {
  const [totalLockData, setTotalLockData] = useState(0)
  const [LPData, setLPData] = useState([])
  const [selectFlag, setSelectFlag] = useState(false)
  const [allTokens] = useState(Object.values(useAllTokens()))
  const explanation = (e, indexLP) => {
    e.stopPropagation()
    let LPDataNew = LPData.concat()
    LPDataNew.map((item, index) => {
      if (index === indexLP) {
        LPDataNew[indexLP].flag = !LPDataNew[indexLP].flag
      } else {
        item.flag = false
      }
    })
    setLPData(LPDataNew)
  }
  const goBankIncome = (token, rewardToken) => {
    localStorage.setItem('tokenTvl', token)
    localStorage.setItem('rewardTokenTvl', rewardToken)
    props.history.push({ pathname: './bankIncome' })
  }

  const getLPData = async flag => {
    const address = await getUserAddress()
    let sum = 0
    const res = await networkRequest(`api/get-addr-lp/${address}?switchPersonal=${flag ? 'yes' : 'no'}`)
    if (res) {
      res.data.map(item => {
        item.flag = false
        sum = sum + item.pledge_amount
        const tokenArr = item.token.split('/')
        allTokens.map(item1 => {
          if (tokenArr[0] === item1.symbol) {
            item.tokenImg1 = `/images/token/${item1.symbol}.png`
          }
          if (tokenArr[1] === item1.symbol) {
            item.tokenImg2 = `/images/token/${item1.symbol}.png`
          }
        })
      })
      setLPData(res.data)
      setTotalLockData(sum.toFixed(2))
    }
  }

  const getAllData = flag => {
    closeTimer = setTimeout(function() {
      getAllData(flag)
    }, 600000)
    getLPData(flag)
  }

  const myParticipate = () => {
    clearTimeout(closeTimer)
    setSelectFlag(!selectFlag)
    getAllData(!selectFlag)
  }

  useEffect(() => {
    getAllData(false)
    return () => {
      clearTimeout(closeTimer)
    }
  }, [])

  return (
    <>
      <AppBody></AppBody>
      <PoolHeader url={window.location.href} />
      <div style={{ height: 66 }}></div>
      <div className="lockTotal">
        <div>{intl.get('TotalLock')}（USDT）</div>
        <div>{totalLockData}</div>
      </div>
      <div className="empty"></div>
      <div onClick={() => myParticipate()} className="participate">
        <img src={selectFlag ? select : noSelect} alt=""></img>
        <div>{intl.get('involved')}</div>
      </div>
      <div className="empty"></div>
      <div className="list">
        {LPData.map((item, indexLP) => (
          <div className="listItem" onClick={() => goBankIncome(item.token, item.reward_token)} key={indexLP}>
            <div className="listItemF">
              <div className="listItemFLogo">
                <img src={item.tokenImg1} />
                <span style={{ marginRight: 4, marginLeft: 4 }}>+</span>
                <img src={item.tokenImg2} />
                <span style={{ fontSize: 13, marginLeft: 9 }}>{item.token}</span>
              </div>
              <div className="listItemFEarn">
                <span style={{ color: '#B0B0B0', marginRight: 4 }}>{intl.get('earn')}</span>
                <span>{item.total_reward}</span>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="listData">
                <div className="listDataFR">TVL</div>
                <div className="listDataSR">{item.pledge_amount}</div>
              </div>
              <div className="listData">
                <div className="listDataFR">
                  {intl.get('personalIncome')} ({item.reward_token})
                </div>
                <div style={{ textAlign: 'center' }} className="listDataSR">
                  {item.daily_output}
                </div>
              </div>
              <div className="listData" onClick={e => explanation(e, indexLP)}>
                <div className="listDataFR">APR</div>
                <div className="listAPR">
                  <div className="listDataSR">{item.apr}%</div>
                  <div>?</div>
                </div>
              </div>
            </div>
            <div className="explanation" style={{ display: item.flag ? 'block' : 'none' }}>
              <div className="exAPR">
                <span>(APR)</span>
                <span>{item.apr}%</span>
              </div>
              <div className="exImg">
                <img alt="" src={line}></img>
                <span>{intl.get('liquidityMining')}</span>
                <span>{item.flow_mining}%</span>
              </div>
              <div className="exAPR">
                <span>(APY)</span>
                <span>{item.apy}%</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer index={1} />
    </>
  )
}

export default BankIncomeList
