import React, { useEffect, useState, useRef } from 'react'
// import Spin from '../components/Spin'
import './invitationDetails.css'
import Spin from '../Bank/Spin'
import { serverLink } from '../../store/constants'
import { getUserAddress } from '../../utils/playerAddr'
import intl from 'react-intl-universal'

// const typeObj = { 3: '30天', 6: '90天', 9: '180天', 12: '365天' }

let page = 1
const InvitationDetails = props => {
  const [currentTab, setCurrentTab] = useState(3)
  const [listData, setListData] = useState({ list: [] })
  const [userAddress, setUserAddress] = useState('')
  const [loading, setLoading] = useState(false)
  const [flagError, setFlagError] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [countdown, setCountdown] = useState(2)
  const time = useRef(null)
  const arrTab = [
    { id: 3, text: intl.get('directFriend') },
    { id: 4, text: intl.get('diffusionFriends') }
  ]
  //倒计时
  const countdownFun = () => {
    setCountdown(2)
    time.current = setInterval(() => {
      setCountdown(countdown => countdown - 1)
    }, 1000)
  }
  const getData = async (type, address, tab) => {
    setLoading(true)
    try {
      const res = await (
        await fetch(`${serverLink}/api/get-season-my-invite-sum/${address}/${type}?page=${page}
      `)
      ).json()
      if (res.code === 0) {
        setLoading(false)
        if (res.data.top_player_name) {
          let n = res.data.top_player_name
          res.data.top_player_name = n.substring(0, 2) + '...' + n.substring(n.length - 4)
        }
        if (res.data.list) {
          res.data.list.map(item => {
            // item.content = JSON.parse(item.content)
            let s = item.related_addr
            item.relatedPlayerStr = s.substring(0, 2) + '...' + s.substring(s.length - 4)
          })
        }
        if (res.data.total_reward_list) {
          let sum = 0
          res.data.total_reward_list.map(item => {
            sum += Number(item.equal_u)
          })
          res.data.total_reward = sum
        }
        if (tab !== 1) {
          res.data.list = listData.list.concat(res.data.list)
        }
        setListData(res.data)
      } else {
        setLoading(false)
        setFlagError(true)
        setMessageError(res.msg)
        countdownFun()
      }
    } catch (e) {
      setLoading(false)
      setFlagError(true)
      setMessageError(e.message)
      countdownFun()
    }
  }
  const lookMore = () => {
    page = page + 1
    getData(currentTab, userAddress)
  }
  const clickTab = id => {
    page = 1
    setCurrentTab(id)
    setListData({ list: [] })
    getData(id, userAddress, 1)
  }
  useEffect(() => {
    if (countdown < 0) {
      setFlagError(false)
      setMessageError('')
      clearInterval(time.current)
    }
  }, [countdown])
  useEffect(() => {
    page = 1
    getUserAddress().then(r => {
      setUserAddress(r)
      getData(3, r)
    })
  }, [])
  return (
    <>
      {loading ? (
        <div className="spin-bg">
          <Spin />
        </div>
      ) : null}
      <div className="id-top">
        <div className="id-last">
          <div>{intl.get('superior')}</div>
          <div>{listData.top_player_name ? listData.top_player_name : intl.get('noSuperior')}</div>
        </div>
        <div className="id-inviteData">
          <div className="id-inviteP">
            <div>{intl.get('successInvited')}</div>
            <div>{listData.direct_count || 0}</div>
          </div>
          <div className="id-inviteP">
            <div>{intl.get('rewarded')}</div>
            <div>{listData.total_reward || 0}</div>
          </div>
        </div>
        <div className="id-tab">
          {arrTab.map((item, index) => {
            return (
              <div
                onClick={() => clickTab(item.id)}
                className={currentTab - 3 === index ? 'id-selected' : 'id-noSelected'}
                key={index}
              >
                {item.text}
              </div>
            )
          })}
        </div>
      </div>
      <div className="id-detail">
        <div className="id-detailHeader">
          <div>{intl.get('address')}</div>
          <div>{intl.get('lockType')}</div>
          <div>{intl.get('tradingVolume')}</div>
          <div>{intl.get('contributeCourage')}</div>
        </div>
        {listData.list.length === 0 ? (
          <div className="id-listItem">{currentTab == 3 ? intl.get('noDirectF') : intl.get('noSpreadF')}</div>
        ) : (
          ''
        )}
        {listData.list.map((item, index) => {
          return (
            <div className="id-listItem" key={index}>
              <div>{item.relatedPlayerStr}</div>
              <div>{item.pledge_day}</div>
              <div>{item.pledge_amount}</div>
              <div>{item.amount}</div>
            </div>
          )
        })}
        {listData.list.length < listData.count ? (
          <div className="id-more" onClick={() => lookMore()}>
            {intl.get('lookMore')}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="modal" style={{ display: flagError ? 'flex' : 'none' }}>
        <div className="modalContent">
          <div style={{ color: 'red', fontSize: 12 }}>{messageError}</div>
        </div>
      </div>
    </>
  )
}

export default InvitationDetails
