import Web3 from 'web3'
import * as constants from '../store/constants'
import Erc20Abi from '../erc20.json'
import depositAbi from '../token-deposit.abi.json'
import convertAbi from '../convert_tool.abi.json'
import rewardAbi from '../spread_reward_pool.abi.json'
import dkExchangeAbi from '../DKConvert.json'
import hjwReleaseAbi from '../token_release.json'

let AuraDecimals = 18

let _web3
export const web3 = () => {
  if (!_web3) _web3 = new Web3(window.ethereum)
  if (constants.testAddr) {
    _web3.eth.getAccounts = () => {
      return [constants.testAddr]
    }
  }
  return _web3
}
//当前用户地址
export const getUserAddress = async () => {
  const accounts = await web3().eth.getAccounts()
  const account = accounts[0]
  return account
}
//调起MetaMask
export const getAccount = async function() {
  let accounts = await web3().eth.requestAccounts()
  return accounts[0]
}

//lp挖矿 余额
let erc20ContractMap = {}
export function getErc20Contract(tokenAddress) {
  if (!erc20ContractMap[tokenAddress]) {
    erc20ContractMap[tokenAddress] = new (web3().eth.Contract)(Erc20Abi, tokenAddress)
  }
  return erc20ContractMap[tokenAddress]
}

export async function execute(account, lpAddress) {
  let balance = await getErc20Contract(lpAddress)
    .methods.balanceOf(account)
    .call()
  balance = balance / Math.pow(10, AuraDecimals)
  return balance
}
// 当前地址是否将LP授权了足够数额给质押合约
export async function allowanceLP(account, pledgeAddr, lpAddress) {
  let balance = await getErc20Contract(lpAddress)
    .methods.allowance(account, pledgeAddr)
    .call()
  // balance = balance / Math.pow(10, AuraDecimals)
  return balance
}
// 当前地址授权数额给质押合约
export async function approveLP(pledgeAddr, amount, addr, lpAddress) {
  await getErc20Contract(lpAddress)
    .methods.approve(pledgeAddr, amount)
    .send({ from: addr })
}

//提取lp
let depositMap = {}
export const getDepositContract = tokenAddress => {
  if (!depositMap[tokenAddress]) {
    depositMap[tokenAddress] = new (web3().eth.Contract)(depositAbi, tokenAddress)
  }
  return depositMap[tokenAddress]
}

let convertMap = {}
export const getConvertContract = tokenAddress => {
  if (!convertMap[tokenAddress]) {
    convertMap[tokenAddress] = new (web3().eth.Contract)(convertAbi, tokenAddress)
  }
  return convertMap[tokenAddress]
}

// 跨链转账
export async function getCollectionToken(contractAddr, auraAddr, addr, data) {
  // console.log('data', data)
  await getConvertContract(contractAddr)
    .methods.collectionToken(auraAddr, data.total_amount, data.deadline, data.code, data.v, data.r, data.s)
    .send({ from: addr })
}

let rewardMap = {}
export const getRewardContract = tokenAddress => {
  if (!rewardMap[tokenAddress]) {
    rewardMap[tokenAddress] = new (web3().eth.Contract)(rewardAbi, tokenAddress)
  }
  return rewardMap[tokenAddress]
}
//dk 兑换
//tokenAddress dk兑换的合约地址
let dk = null
export function getDkContract(tokenAddress) {
  if (!dk) {
    dk = new (web3().eth.Contract)(dkExchangeAbi, tokenAddress)
  }
  return dk
}
//DK地址
export async function getDKaddr(tokenAddress) {
  let addr = await getDkContract(tokenAddress)
    .methods.DKaddr()
    .call()
  return addr
}
//DK2地址
export async function getDK2addr(tokenAddress) {
  let addr2 = await getDkContract(tokenAddress)
    .methods.DK2addr()
    .call()
  return addr2
}
//DK滑点
export async function getDKSlippage(tokenAddress) {
  let value = await getDkContract(tokenAddress)
    .methods.getDkFee()
    .call()
  return value
}
//传入dk需转换的数量自动计算出可获得的DK2数量
export async function getDK2(tokenAddress, num) {
  let value = await getDkContract(tokenAddress, num)
    .methods.getDkToDK2(num)
    .call()
  console.log('value1', value)
  value = value / Math.pow(10, AuraDecimals)
  return value
}
//传入dk2需转换的数量自动计算出可获得的DK数量
export async function getDK(tokenAddress, num) {
  let value = await getDkContract(tokenAddress, num)
    .methods.getDk2ToDK(num)
    .call()
  value = value / Math.pow(10, AuraDecimals)
  return value
}
//执行DK兑换DK2操作
export async function DkToDK2Exchange(tokenAddress, num, transferWallet, userAddr) {
  await getDkContract(tokenAddress, num)
    .methods.DkToDk2(transferWallet, num)
    .send({ from: userAddr })
}
//执行DK2兑换DK操作
export async function Dk2ToDKExchange(tokenAddress, num, transferWallet, userAddr) {
  await getDkContract(tokenAddress, num)
    .methods.Dk2ToDk(transferWallet, num)
    .send({ from: userAddr })
}

//hjw释放合约
//contractAddress 合约地址
let hjwRelease = null
export function getHJWContract(contractAddress) {
  if (!hjwRelease) {
    hjwRelease = new (web3().eth.Contract)(hjwReleaseAbi, contractAddress)
  }
  return hjwRelease
}
//执行兑换hjw heco兑换到bsc tokenAddress代币地址
export async function hjwHecoToBsc(contractAddress, num, tokenAddress, userAddr) {
  await getHJWContract(contractAddress)
    .methods.investment(tokenAddress, num)
    .send({ from: userAddr })
}
// hjw提取
export async function hjwExtract(
  contractAddress,
  historicalWithdrawalNumber,
  tokenAddress,
  amount,
  deadline,
  v,
  r,
  s,
  userAddr
) {
  await getHJWContract(contractAddress)
    .methods.withdrawalToken(historicalWithdrawalNumber, tokenAddress, amount, deadline, v, r, s)
    .send({ from: userAddr })
}
