import { Text } from 'rebass'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const ClickableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
  color: ${({ theme }) => theme.primary1};
`
export const MaxButton = styled.button<{ width: string }>`
  background-image: linear-gradient(#fc87d1, #f84fa5);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  color: white;
  height: 39px;
  width: 23%;
  border: none;
  :hover {
    border: 1px solid white;
  }
  :focus {
    border: 1px solid white;
    outline: none;
  }
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`
