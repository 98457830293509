import React from 'react'
import ReactDOM from 'react-dom'
import MessageModal from './messageModal'
import Spin from './spin'
export const message = {
  dom: null,
  popup(message) {
    // 创建一个dom
    this.dom = document.createElement('div')
    // 定义组件，
    const JSXdom = <MessageModal message={message} />
    // 渲染DOM
    ReactDOM.render(JSXdom, this.dom)
    // 置入到body节点下
    document.body.appendChild(this.dom)
  }
  //   error({ content, duration }) {
  //     this.dom = document.createElement('div')
  //     const JSXdom = <Message content={content} duration={duration} type="error"></Message>
  //     ReactDOM.render(JSXdom, this.dom)
  //     document.body.appendChild(this.dom)
  //   },
  //   warning({ content, duration }) {
  //     this.dom = document.createElement('div')
  //     const JSXdom = <Message content={content} duration={duration} type="warning"></Message>
  //     ReactDOM.render(JSXdom, this.dom)
  //     document.body.appendChild(this.dom)
  //   },
  //   info({ content, duration }) {
  //     this.dom = document.createElement('div')
  //     const JSXdom = <Message content={content} duration={duration} type="warning"></Message>
  //     ReactDOM.render(JSXdom, this.dom)
  //     document.body.appendChild(this.dom)
  //   }
}

export const loading = {
  dom: null,
  show() {
    // 创建一个dom
    this.dom = document.createElement('div')
    // 定义组件，
    const JSXdom = <Spin />
    // 渲染DOM
    ReactDOM.render(JSXdom, this.dom)
    // 置入到body节点下
    document.body.appendChild(this.dom)
  },
  close() {
    this.dom && ReactDOM.unmountComponentAtNode(this.dom) //从dom中移除已挂载的Loading组件
    this.dom && this.dom.parentNode && this.dom.parentNode.removeChild(this.dom) //移除挂载的容器
  }
}
