import React, { useCallback, useState } from 'react'
// import { HelpCircle as Question } from 'react-feather'
import styled from 'styled-components'
import Tooltip from '../Tooltip'

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #b3b3b3;
  color: black;
  font-size: 9px;
  font-weight: bold;
  :hover,
  :focus {
    opacity: 0.7;
  }
`

export default function QuestionHelper({ text }: { text: string }) {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <span style={{ marginLeft: 4, fontSize: 0 }}>
      <Tooltip text={text} show={show}>
        <QuestionWrapper onClick={open} onMouseEnter={open} onMouseLeave={close}>
          <div>?</div>
        </QuestionWrapper>
      </Tooltip>
    </span>
  )
}
