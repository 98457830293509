import styles from '../../style/message/spin.module.css'
import React from 'react'
const Spin = () => {
  return (
    <div className={styles.spinBg}>
      <div className={styles.spin}>
        <span className={`${styles.spinDot} ${styles.spinDotSpin}`}>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </span>
      </div>
    </div>
  )
}

export default Spin
