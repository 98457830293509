import React, { useEffect, useRef, useState } from 'react'
import styles from '../../style/message/message.module.css'

export default function Message({ message }) {
  const [countdown, setCountdown] = useState(2)
  const [flag, setFlag] = useState(true)
  const time = useRef(null)
  const countdownFun = () => {
    setCountdown(2)
    time.current = setInterval(() => {
      setCountdown(countdown => countdown - 1)
    }, 1000)
  }
  useEffect(() => {
    if (countdown < 0) {
      setFlag(false)
      clearInterval(time.current)
    }
  }, [countdown])
  useEffect(() => {
    countdownFun()
  }, [])
  return (
    <div className={styles.modal} style={{ display: flag ? 'flex' : 'none' }}>
      <div className={styles.modalContent}>
        <div style={{ color: 'red', fontSize: 12 }}>{message}</div>
      </div>
    </div>
  )
}
