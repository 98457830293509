import React, { useState, useEffect } from 'react'
import styles from '../../style/jianghuOrder/gameNode.module.css'
import intl from 'react-intl-universal'
import FilterIcon from '../../assets/images/jianghuOrder/filter.png'
import Close from '../../assets/images/jianghuOrder/close.png'
import ArrowL from '../../assets/images/jianghuOrder/arrowL.png'
import ArrowR from '../../assets/images/jianghuOrder/arrowR.png'
import EmptyImg from '../../assets/images/jianghuOrder/empty.png'
import dropDown from '../../assets/images/jianghuOrder/dropDown.png'
import selectedImg from '../../assets/images/languageSelect.png'
import { getUserAddress } from '../../utils/playerAddr'
import { serverLink } from '../../store/constants'
import Spin from '../../pages/Bank/Spin'
import { message } from '../Message/message'
let page = 1
export default function GameNode() {
  const [loading, setLoading] = useState(false)
  const [userAddress, setUserAddress] = useState('')
  const [currentTab, setCurrentTab] = useState(0)
  const [friendList, setFriendList] = useState([])
  const [teamList, setTeamList] = useState([])
  const [flag, setFlag] = useState(false)
  const [filterList, setFilterList] = useState([])
  const [gameTypeFlag, setGameTypeFlag] = useState(false)
  const [count, setCount] = useState(0)
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1)
  const [currentYear] = useState(new Date().getFullYear())
  const [gameType, setGameType] = useState(0)
  const [gameTypeList] = useState([
    { text: '全部', flag: false, type: 0 },
    { text: '五行局', flag: false, type: 5 },
    { text: '阴阳局', flag: false, type: 2 }
  ])
  const gameTypeObj = { 2: '阴阳局', 5: '五行局' }
  const arrTab = [intl.get('directFriend'), intl.get('teamPromotion')]
  //直邀好友列表
  const getDirectFriendList = async (addr, gameType, more) => {
    try {
      setLoading(true)
      const res = await (
        await fetch(`${serverLink}/api/player/spread-referrer-data/${addr}?page=${page}&gameType=${gameType}
      `)
      ).json()
      setLoading(false)
      if (res.code === 0) {
        setCount(res.data.count)
        if (res.data.list) {
          if (more === 'more') {
            res.data.list = friendList.concat(res.data.list)
          }
          setFriendList(res.data.list)
        }
      } else {
        message.popup(res.msg)
      }
    } catch (e) {
      setLoading(false)
      message.popup(e.message)
    }
  }
  //团队推广列表
  const getTeamPromotionList = async (addr, month, more) => {
    try {
      setLoading(true)
      const res = await (
        await fetch(`${serverLink}/api/player/spread-team-promotion-data/${addr}?page=${page}&year=${currentYear}&month=${month}
      `)
      ).json()
      setLoading(false)
      if (res.code === 0) {
        setCount(res.data.count)
        if (res.data.list) {
          if (more === 'more') {
            res.data.list = teamList.concat(res.data.list)
          }
          setTeamList(res.data.list)
        }
      } else {
        message.popup(res.msg)
      }
    } catch (e) {
      setLoading(false)
      message.popup(e.message)
    }
  }
  //切换tab
  const clickTab = index => {
    setCurrentTab(index)
    setCount(0)
    page = 1
    if (index === 0) {
      getDirectFriendList(userAddress, gameType)
    }
    if (index === 1) {
      getTeamPromotionList(userAddress, currentMonth)
    }
  }
  //查看更多
  const lookMore = () => {
    page = page + 1
    if (currentTab == 0) {
      getDirectFriendList(userAddress, gameType, 'more')
    }
    if (currentTab == 1) {
      getTeamPromotionList(userAddress, currentMonth, 'more')
    }
  }
  const openFilter = () => {
    setFlag(true)
  }
  const closeModule = () => {
    setFlag(false)
  }
  const chooseMonth = item => {
    setCurrentMonth(item)
    page = 1
    getTeamPromotionList(userAddress, item)
    setFlag(false)
  }
  //弹窗月的列表
  const getFilterList = () => {
    const list = []
    for (let i = 1; i < 13; i++) {
      list.push(i)
    }
    setFilterList(list)
  }
  //筛选完成局数框显示隐藏
  const filterRounds = () => {
    setGameTypeFlag(!gameTypeFlag)
  }
  //选择完成局数模式
  const selectGameType = type => {
    setGameType(type)
    getDirectFriendList(userAddress, type)
    setGameTypeFlag(false)
    page = 1
  }
  useEffect(() => {
    getFilterList()
    getUserAddress().then(r => {
      setUserAddress(r)
      getDirectFriendList(r, gameType)
    })
  }, [])
  return (
    <>
      {loading ? (
        <div className="spin-bg">
          <Spin />
        </div>
      ) : null}
      <div className={styles.tab}>
        {arrTab.map((item, index) => {
          return (
            <div
              onClick={() => clickTab(index)}
              className={currentTab === index ? styles.selected : styles.noSelected}
              key={index}
            >
              {item}
            </div>
          )
        })}
        <div
          className={styles.filter}
          onClick={() => {
            openFilter()
          }}
          style={{ display: currentTab === 1 ? 'block' : 'none' }}
        >
          <img src={FilterIcon} />
          <span>{intl.get('filter')}</span>
        </div>
      </div>
      <div className={styles.detail} style={{ display: currentTab === 0 ? 'block' : 'none' }}>
        <div className={styles.detailHeader}>
          <div>{intl.get('address')}</div>
          <div
            onClick={() => {
              filterRounds()
            }}
          >
            {intl.get('completedRounds')}
            <img src={dropDown} alt="" className={styles.dropDown} />
          </div>
          <div>{intl.get('betAmount')}</div>
          <div>{intl.get('weights')}</div>
          <div className={styles.gameTypePopup} style={{ display: gameTypeFlag ? 'block' : 'none' }}>
            {gameTypeList.map((item, index) => (
              <div
                className={styles.gameTypeItem}
                key={index}
                onClick={() => {
                  selectGameType(item.type)
                }}
              >
                <span style={{ color: item.type === gameType ? '#ffffff' : '#B3B3B3' }}>{item.text}</span>
                <img src={selectedImg} style={{ display: item.type === gameType ? 'block' : 'none' }} />
              </div>
            ))}
          </div>
        </div>
        {friendList.length === 0 ? (
          <div className={styles.empty}>
            <img src={EmptyImg} />
            <div>{intl.get('emptyList')}</div>
          </div>
        ) : (
          ''
        )}
        {friendList.map((item, index) => {
          return (
            <div className={styles.listItem} key={index}>
              <div>{item.Addr}</div>
              <div>
                {item.NumGames}
                <div className={styles.listItemToken}>{gameTypeObj[item.GameType]}</div>
              </div>
              <div>
                {item.Bets}
                <div className={styles.listItemToken}>{item.Token}</div>
              </div>
              <div>{item.Weight}%</div>
            </div>
          )
        })}
        {friendList.length < count ? (
          <div className={styles.more} onClick={() => lookMore()}>
            {intl.get('lookMore')}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className={styles.detail} style={{ display: currentTab === 1 ? 'block' : 'none' }}>
        <div className={styles.teamDetailHeader}>
          <div>{intl.get('teamBetTotal')}</div>
          <div>{intl.get('date')}</div>
        </div>
        {teamList.length === 0 ? (
          <div className={styles.empty}>
            <img src={EmptyImg} />
            <div>{intl.get('emptyList')}</div>
          </div>
        ) : (
          ''
        )}
        {teamList.map((item, index) => {
          return (
            <div className={styles.teamListItem} key={index}>
              <div>
                {item.Bets}
                {item.Token}
                <span>≈{item.EqU}U</span>
              </div>
              <div>{item.DayStr}</div>
            </div>
          )
        })}
        {teamList.length < count ? (
          <div className={styles.more} onClick={() => lookMore()}>
            {intl.get('lookMore')}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className={styles.filterModal} style={{ display: flag ? 'flex' : 'none' }}>
        <div className={styles.filterModalCon}>
          <div className={styles.filterModalHeader}>
            <div>{intl.get('filter')}</div>
            <img src={Close} onClick={() => closeModule()} />
          </div>
          <div className={styles.filterModalYear}>
            <img src={ArrowL} />
            <div>
              {currentYear} {intl.get('year')}
            </div>
            <img src={ArrowR} />
          </div>
          <div className={styles.filterModalMonth}>
            {filterList.map((item, index) => (
              <div
                key={item}
                className={currentMonth === item ? styles.selectedM : styles.selectM}
                onClick={() => {
                  chooseMonth(item)
                }}
              >
                {item}
                {intl.get('month')}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
