import React, { useEffect, useState, useRef } from 'react'
import Spin from '../Bank/Spin'
import './energyDetails.css'
import energyLogo from '../../assets/images/jianghuOrder/energyLogo.png'
import { serverLink } from '../../store/constants'
import { getUserAddress } from '../../utils/playerAddr'
import intl from 'react-intl-universal'

let page = 1
const EnergyDetails = props => {
  const [listData, setListData] = useState({ list: [] })
  const [loading, setLoading] = useState(false)
  const [flagError, setFlagError] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [countdown, setCountdown] = useState(2)
  const [userAddress, setUserAddress] = useState('')
  const time = useRef(null)

  //倒计时
  const countdownFun = () => {
    setCountdown(2)
    time.current = setInterval(() => {
      setCountdown(countdown => countdown - 1)
    }, 1000)
  }

  //列表
  const getListData = async address => {
    setLoading(true)
    try {
      const res = await (
        await fetch(`${serverLink}/api/get-season-energy-log/${address}?page=${page}
      `)
      ).json()

      if (res.code === 0) {
        setLoading(false)
        if (res.data.parent_name) {
          let n = res.data.parent_name
          res.data.parent_name = n.substring(0, 2) + '...' + n.substring(n.length - 4)
        }
        if (res.data.list) {
          res.data.list.map(item => {
            item.content = JSON.parse(item.content)
          })
        }
        res.data.list = listData.list.concat(res.data.list)
        setListData(res.data)
      } else {
        setLoading(false)
        setFlagError(true)
        setMessageError(res.msg)
        countdownFun()
      }
    } catch (e) {
      setLoading(false)
      setFlagError(true)
      setMessageError(e.message)
      countdownFun()
    }
  }
  const lookMore = () => {
    page = page + 1
    getListData(userAddress)
  }
  useEffect(() => {
    if (countdown < 0) {
      setFlagError(false)
      setMessageError('')
      clearInterval(time.current)
    }
  }, [countdown])
  useEffect(() => {
    page = 1
    getUserAddress().then(r => {
      setUserAddress(r)
      getListData(r)
    })
  }, [])
  return (
    <>
      {loading ? (
        <div className="spin-bg">
          <Spin />
        </div>
      ) : null}
      <div className="ed-top">
        <div className="ed-last">
          <div>{intl.get('superior')}</div>
          <div>{listData.parent_name ? listData.parent_name : intl.get('noSuperior')}</div>
        </div>
        <div className="ed-energy">
          <div className="ed-energyData">
            <div className="ed-energyTitle">
              <img src={energyLogo} alt="" />
              <div>{intl.get('myCourage')}</div>
            </div>
            <div className="ed-energyCount">{listData.oneself_season_energy}</div>
          </div>
          <div className="ed-end">
            {intl.get('countdownSeason')}
            {localStorage.getItem('countdownTime') || '--'}
          </div>
        </div>
      </div>
      <div className="ed-detail">
        <div className="ed-detailHeader">
          <div>{intl.get('type')}</div>
          <div>{intl.get('courage')}</div>
        </div>
        {listData.list.length === 0 ? <div className="ed-listItem">{intl.get('noEnergyDetails')}</div> : ''}
        {listData.list.map((item, index) => {
          return (
            <div className="ed-listItem" key={index}>
              <div>{item.content.type}</div>
              <div>{item.change_amount}</div>
            </div>
          )
        })}
        {listData.list.length < listData.count ? (
          <div className="ed-more" onClick={() => lookMore()}>
            {intl.get('lookMore')}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="modal" style={{ display: flagError ? 'flex' : 'none' }}>
        <div className="modalContent">
          <div style={{ color: 'red', fontSize: 12 }}>{messageError}</div>
        </div>
      </div>
    </>
  )
}

export default EnergyDetails
