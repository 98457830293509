import { Currency,
  //  Token, 
  // ETHER
 } from '@uniswap/sdk'
import React, { useCallback, useEffect,
  //  useMemo,
   useRef,
    // useState,
  //  RefObject, KeyboardEvent 
  } from 'react'
// import ReactGA from 'react-ga'
import { FixedSizeList } from 'react-window'
// import { Text } from 'rebass'
// import { useActiveWeb3React } from '../../hooks'
// import { useAllTokens,
  //  useToken
//  } from '../../hooks/Tokens'
// import { CloseIcon } from '../../theme'
// import { isAddress } from '../../utils'
import Column from '../Column'
// import { RowBetween } from '../Row'
// import CommonBases from './CommonBases'
import CurrencyList from './CurrencyList2'
// import { filterTokens } from './filtering'
// import { useTokenComparator } from './sorting'
// import { PaddedColumn, SearchInput } from './styleds'
import AutoSizer from 'react-virtualized-auto-sizer'
// import styled from 'styled-components'
// import intl from 'react-intl-universal'

interface CurrencySearchProps {
  tokens: Currency[]
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  // otherSelectedCurrency?: Currency | null
  // showCommonBases?: boolean
  onChangeList: () => void
}

// const CloseIconContainer = styled.div`
//   height: 34px;
//   line-height: 34px;
//   width: 34px;
//   text-align: center;
//   background: #313135;
//   border-radius: 50%;
//   color: gray;
//   padding-top: 4px;
// `

export function CurrencySearch({
  tokens,
  selectedCurrency,
  onCurrencySelect,
  // otherSelectedCurrency,
  // showCommonBases,
  onDismiss,
  isOpen,
  onChangeList
}: CurrencySearchProps) {

  const fixedList = useRef<FixedSizeList>()
  // const [searchQuery, setSearchQuery] = useState<string>('')
  // const [invertSearchOrder] = useState<boolean>(false)
  // const allTokens = useAllTokens()

  // if they input an address, use it
  // const isAddressSearch = isAddress(searchQuery)
  // const searchToken = useToken(searchQuery)

  useEffect(() => {
    // if (isAddressSearch) {
    //   ReactGA.event({
    //     category: 'Currency Select',
    //     action: 'Search by address',
    //     label: isAddressSearch
    //   })
    // }
  }, [
    // isAddressSearch
  ])


  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency)
      onDismiss()
    },
    [onDismiss, onCurrencySelect]
  )

  // const showETH: boolean = useMemo(() => {
  //   return false
  // }, [searchQuery])

  // const tokenComparator = useTokenComparator(invertSearchOrder)

  // const filteredTokens: Token[] = useMemo(() => {
  //   // if (isAddressSearch) return searchToken ? [searchToken] : []
  //   // return filterTokens(Object.values(allTokens), searchQuery)
  // }, [
  //   // isAddressSearch, searchToken, allTokens, searchQuery
  // ])

  // const filteredSortedTokens: Token[] = useMemo(() => {
    // if (searchToken) return [searchToken]
    // const sorted = filteredTokens.sort(tokenComparator)
    // const symbolMatch = searchQuery
    //   .toLowerCase()
    //   .split(/\s+/)
    //   .filter(s => s.length > 0)

    // if (symbolMatch.length > 1) return sorted
    // let newSorted:Array<any>=[]
    // sorted.forEach(value => {
    //   newSorted.push(value)
    // })

    // return tokens.sort(tokenComparator)
    // return [
    //   ...(searchToken ? [searchToken] : []),
    //   // sort any exact symbol matches first
    //   ...newSorted.filter(token => token.symbol?.toLowerCase() === symbolMatch[0]),
    //   ...newSorted.filter(token => token.symbol?.toLowerCase() !== symbolMatch[0])
    // ]
  // }, [
    // filteredTokens, searchQuery, searchToken, tokenComparator
  // ])

  // const handleEnter = useCallback(
  //   (e: KeyboardEvent<HTMLInputElement>) => {
  //     if (e.key === 'Enter') {
  //       const s = searchQuery.toLowerCase().trim()
  //       if (s === 'eth') {
  //         handleCurrencySelect(ETHER)
  //       } else if (filteredSortedTokens.length > 0) {
  //         if (
  //           filteredSortedTokens[0].symbol?.toLowerCase() === searchQuery.trim().toLowerCase() ||
  //           filteredSortedTokens.length === 1
  //         ) {
  //           handleCurrencySelect(filteredSortedTokens[0])
  //         }
  //       }
  //     }
  //   },
  //   [filteredSortedTokens, handleCurrencySelect, searchQuery]
  // )

  // clear the input on open
  // useEffect(() => {
  //   if (isOpen) setSearchQuery('')
  // }, [isOpen])

  // manage focus on modal show

  return (
    <Column style={{ width: '100%', flex: '1 1' }}>
      {/* <PaddedColumn gap="14px">
        <RowBetween>
          <Text fontWeight={500} fontSize={16}>
            {intl.get('selectToken')}
          </Text>
          <CloseIconContainer>
            <CloseIcon onClick={onDismiss} />
          </CloseIconContainer>
        </RowBetween>
        <SearchInput
          type="text"
          id="token-search-input"
          placeholder="search"
          value={searchQuery}
          ref={inputRef as RefObject<HTMLInputElement>}
          onChange={handleInput}
          onKeyDown={handleEnter}
        />
        {showCommonBases && (
          <CommonBases chainId={chainId} onSelect={handleCurrencySelect} selectedCurrency={selectedCurrency} />
        )}
        <RowBetween>
          <Text fontSize={14} fontWeight={500}>
            {intl.get('currencyList')}
          </Text>
        </RowBetween>
      </PaddedColumn> */}

      <div style={{ flex: '1' }}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <CurrencyList
              height={height}
              // showETH={showETH}
              //币种列表
              // currencies={filteredSortedTokens}
              currencies={tokens}
              onCurrencySelect={handleCurrencySelect}
              // otherCurrency={otherSelectedCurrency}
              selectedCurrency={selectedCurrency}
              fixedListRef={fixedList}
            />
          )}
        </AutoSizer>
      </div>
    </Column>
  )
}
