import React, { useState, useEffect } from 'react'
import AppBody from '../AppBody'
import './nameList.css'
import one from './img/one.png'
import two from './img/two.png'
import three from './img/three.png'
import nameListImg from './img/nameListImg.png'
const imgObj = { 0: one, 1: two, 2: three }
let list1 = [
  {
    name: '江湖艺名222',
    state: '董事',
    amount: '88888'
  },
  {
    name: '江艺名',
    state: '董事',
    amount: '88'
  },
  {
    name: '江湖艺名5555555555',
    state: '董事',
    amount: '88888'
  },
  {
    name: '江艺名',
    state: '董事',
    amount: '8'
  },
  {
    name: '江名',
    state: '董事',
    amount: '88888'
  },
  {
    name: '名',
    state: '董事',
    amount: '888'
  },
  {
    name: '江湖艺名345',
    state: '董事',
    amount: '88888'
  },
  {
    name: '名',
    state: '董事',
    amount: '888'
  },
  {
    name: '名',
    state: '董事',
    amount: '888'
  },
  {
    name: '名',
    state: '董事',
    amount: '888'
  }
]
const NameList = props => {
  const [list, setList] = useState([])

  useEffect(() => {
    setList(list1)
    document.title = '排行榜'
  }, [])

  return (
    <>
      <AppBody></AppBody>
      {/* <div className="empty"></div> */}
      <div className="nameListMain">
        <img alt="" src={nameListImg} className="nameListImg" />
        <div className="mainContent">
          <div className="mainTitle">
            <div className="mainTitleCon">
              <div>TOP 1-51 即成为董事并获得SWAP 70% 收益</div>
              <div>*每天24点更新排名</div>
            </div>
          </div>
          <div className="ranking">
            <div className="rankingTitle">
              <span>排名</span>
              <span>江湖艺名</span>
              <span>状态</span>
              <span>质押凭证数</span>
            </div>
            <div className="rankingContent">
              {list.map((item, index) => (
                <div key={index} className={index > 2 ? 'rankingItem' : 'rankingItem rankingItem2'}>
                  {index > 2 ? (
                    <span>{index + 1}</span>
                  ) : (
                    <span>
                      <img alt="" src={imgObj[index]} />
                    </span>
                  )}
                  <span>{item.name}</span>
                  <span>{item.state}</span>
                  <span>{item.amount}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NameList
