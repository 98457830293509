import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import SwapBright from '../../assets/images/footImg/swapBright.png'
import SwapGray from '../../assets/images/footImg/swapGray.png'
import PoolBright from '../../assets/images/footImg/poolBright.png'
import PoolGray from '../../assets/images/footImg/poolGray.png'
import FomoIcon from '../../assets/images/footImg/fomoIcon.png'
// import JiangHuOrderBright from '../../assets/images/footImg/JiangHuOrderBright.png'
import JiangHuOrderGray from '../../assets/images/footImg/JiangHuOrderGray.png'
import BridgeBright from '../../assets/images/footImg/bridgeBright.png'
import BridgeGray from '../../assets/images/footImg/bridgeGray.png'
import NFT from '../../assets/images/footImg/NFT.png'
import more from '../../assets/images/footImg/more.png'
import { fomoUrl } from '../../store/constants'
import { web3 } from '../../utils/playerAddr'
import styles from '../../style/foot.module.css'

// import intl from 'react-intl-universal'

const FootFrame = styled.div`
  width: 100%;
  bottom: 0;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  position: fixed;
  background: #000000;
  height: 83px;
  border-top: 1px solid #333334;
  padding-top: 7px;
  z-index: 3;
`

const FootElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 54px;
`
const Link = styled.a`
  width: 43px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
`
const Icon = styled.img`
  height: auto;
  width: 26px;
`
const LinkTitle = styled.div`
  margin-top: 4px;
  font-size: 12px;
`
const More = styled.div`
  width: 100vw;
  height: 682px;
  position: fixed;
  bottom: 91px;
  background-color: rgba(220, 38, 38, 0);
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`
const MoreList = styled.div`
  padding: 16px;
  border: 1px solid #485053;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  background-color: #1b1a1e;
  width: 141px;
  height: 123px;
  margin-right: 30px;
`
const MoreItem = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  text-decoration: none;
  color: white;
`

const MoreImg = styled.img`
  width: 26px;
  margin-right: 12px;
`

export default function Foot({ index }) {
  const [flag, setFlag] = useState(false)
  const [flagMore, setFlagMore] = useState(false)
  const [menuList, setMenuList] = useState([
    { title: 'Swap', href: '#/swap', iconB: SwapBright, iconG: SwapGray, flag: false },
    { title: 'Pool', href: '#/bankIncomeList', iconB: PoolBright, iconG: PoolGray, flag: false },
    { title: '', href: fomoUrl, iconB: FomoIcon, iconG: FomoIcon, flag: false },
    { title: 'Bridge', href: '#/exchangeHjw', iconB: BridgeBright, iconG: BridgeGray, flag: false },
    // {
    //   title: 'Reward',
    //   href: '#/jianghuOrder',
    //   iconB: JiangHuOrderBright,
    //   iconG: JiangHuOrderGray,
    //   flag: false
    // }
    { title: 'More', iconB: more, iconG: more, flag: false }
  ])

  const getMenuColor = i => {
    console.log(i)
    let list = menuList
    list.forEach(item => {
      item.flag = false
    })
    list[i].flag = true
    setMenuList(list)
  }
  const goMore = index1 => {
    console.log(index1)
    getMenuColor(index1)
    if (index1 === 4) {
      setFlagMore(true)
    }
  }
  const moreList = [
    { title: 'Reword', img: JiangHuOrderGray, href: '#/jianghuOrder' },
    { title: 'NFT', img: NFT, href: 'http://bsc.pet.fivexw.com' }
  ]
  // const showMenu = () => {
  //   setFlagMore(!flagMore)
  // }
  const hideMenu = () => {
    setFlagMore(false)
  }

  useEffect(() => {
    getMenuColor(index)
    web3()
      .eth.net.getId()
      .then(r => {
        if (r === 128) {
          setFlag(true)
        }
      })
  }, [])
  return (
    <>
      <FootFrame style={{ display: flag ? 'none' : 'block' }} className={styles.foot}>
        <FootElement>
          {menuList.map((item, index1) => (
            <Link key={index1} href={item.href} onClick={() => goMore(index1)}>
              <Icon
                style={{ width: index1 === 2 ? 60 : 26, marginTop: index1 === 2 ? -18 : 0 }}
                src={item.flag ? item.iconB : item.iconG}
                alt="logo"
              />
              <LinkTitle style={{ color: item.flag ? '#FB409B' : '#999999', display: index1 === 2 ? 'none' : 'block' }}>
                {item.title}
              </LinkTitle>
            </Link>
          ))}
        </FootElement>
        <More
          style={{ display: flagMore ? 'flex' : 'none' }}
          onClick={() => {
            hideMenu()
          }}
        >
          <MoreList>
            {moreList.map((item, index) => (
              <MoreItem key={index} href={item.href}>
                <MoreImg src={item.img} alt="" />
                <span>{item.title}</span>
              </MoreItem>
            ))}
          </MoreList>
        </More>
      </FootFrame>
    </>
  )
}
