import React, { useState, useEffect, useRef } from 'react'
import styles from './test.module.css'
import AppBody from '../AppBody'
import { Button } from 'antd'
const Test = props => {
  const [flagError, setFlagError] = useState(false)
  const [countdown, setCountdown] = useState(2)
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState('')
  const time = useRef(null)
  //倒计时
  const countdownFun = () => {
    setCountdown(2)
    time.current = setInterval(() => {
      setCountdown(countdown => countdown - 1)
    }, 1000)
  }
  const message = msg => {
    setLoading(false)
    setFlagError(true)
    setMessageError(msg)
    countdownFun()
  }

  useEffect(() => {
    if (countdown < 0) {
      setFlagError(false)
      setMessageError('')
      clearInterval(time.current)
    }
  }, [countdown])
  useEffect(() => {}, [])
  return (
    <>
      <AppBody></AppBody>
      <Button type="primary">hah</Button>
      <h1 className={styles.le}>Hello world</h1>
      {/* <div className="test"></div>
      <div className="modal ac-modal" style={{ display: flagError ? 'flex' : 'flex' }}>
        <div className="modalContent ac-modalContent">
          <div style={{ color: 'red', fontSize: 12 }}>{messageError}</div>
        </div>
      </div> */}
    </>
  )
}

export default Test
