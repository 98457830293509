import React from 'react'
import { Price } from '@uniswap/sdk'
import { Text } from 'rebass'
import PriceChange from '../../assets/images/priceChange.png'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  // const theme = useContext(ThemeContext)
  const formattedPrice = !showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = !showInverted
    ? `1${price?.baseCurrency?.symbol}=${formattedPrice}${price?.quoteCurrency?.symbol}`
    : `1${price?.quoteCurrency?.symbol}=${formattedPrice}${price?.baseCurrency?.symbol}`

  return (
    <Text fontWeight={500} fontSize={14} color={'#ffffff'} style={{ alignItems: 'center', display: 'flex' }}>
      {show ? (
        <>
          <img
            src={PriceChange}
            onClick={() => setShowInverted(!showInverted)}
            style={{ width: 20, height: 20, marginRight: 4 }}
          />
          {formattedPrice ? label : '-'}
        </>
      ) : (
        '-'
      )}
    </Text>
  )
}
