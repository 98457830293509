import React, { useState, useEffect, useRef } from 'react'
import { serverLink } from '../../store/constants'
import './acrossChain.css'
import AppBody from '../AppBody'
import { getUserAddress } from '../../utils/playerAddr'
import Spin from '../Bank/Spin'
import intl from 'react-intl-universal'
let page = 1
const Record = props => {
  const [list, setList] = useState([])
  const [flagError, setFlagError] = useState(false)
  const [countdown, setCountdown] = useState(2)
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [userAddr, setUserAddr] = useState('')
  const [count, setCount] = useState(0)
  const [type, setType] = useState(1)
  const time = useRef(null)
  //倒计时
  const countdownFun = () => {
    setCountdown(2)
    time.current = setInterval(() => {
      setCountdown(countdown => countdown - 1)
    }, 1000)
  }
  const message = msg => {
    setLoading(false)
    setFlagError(true)
    setMessageError(msg)
    countdownFun()
  }
  const getList = async (addr, type) => {
    setLoading(true)
    let requestUrl = type == 1 ? '/api/get-convert-logs/' : '/api/get-swap-dk-convert-logs/'
    try {
      const res = await (
        await fetch(`${serverLink}${requestUrl}${addr == undefined ? '' : addr}?page=${page}
      `)
      ).json()
      if (res.code === 0) {
        setLoading(false)
        setCount(res.data.count)
        res.data.list.map(item => {
          let date = new Date(item.received_time * 1000)
          let year = date.getFullYear()
          let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
          let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
          let h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
          let m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
          let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
          item.newTime = `${year}-${month}-${day} ${h}:${m}:${s}`
        })
        res.data.list = list.concat(res.data.list)
        setList(res.data.list)
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }
  const lookMore = () => {
    page = page + 1
    getList(userAddr, type)
  }
  useEffect(() => {
    if (countdown < 0) {
      setFlagError(false)
      setMessageError('')
      clearInterval(time.current)
    }
  }, [countdown])
  useEffect(() => {
    page = 1
    const { sendType } = props.match.params
    console.log('sendType', sendType)
    setType(sendType)
    getUserAddress().then(addr => {
      setUserAddr(addr)
      getList(addr, sendType)
    })
  }, [])
  return (
    <>
      {loading ? (
        <div className="spin-bg">
          <Spin />
        </div>
      ) : null}
      <AppBody></AppBody>
      <div className="r-record">
        {list.length === 0 ? <div className="r-empty">{intl.get('noTransferR')}</div> : ''}
        {list.map((item, index) => {
          return (
            <div key={index} className="r-list">
              <div className="r-amount">
                {item.amount}
                {item.token}
              </div>
              <div className="r-title r-column">
                <div>From</div>
                <div>To</div>
                <div>{intl.get('time')}</div>
              </div>
              <div className="r-data r-column">
                <div>{item.current_chain}</div>
                <div>{item.target_chain}</div>
                <div>{item.newTime}</div>
              </div>
            </div>
          )
        })}
        {list.length < count ? (
          <div className="r-more" onClick={() => lookMore()}>
            {intl.get('lookMore')}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="modal" style={{ display: flagError ? 'flex' : 'none' }}>
        <div className="modalContent ac-modalContent">
          <div style={{ color: 'red', fontSize: 12 }}>{messageError}</div>
        </div>
      </div>
    </>
  )
}

export default Record
