import { JSBI, Pair, Percent } from '@uniswap/sdk'
import { darken } from 'polished'
import React, { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Text } from 'rebass'
import styled from 'styled-components'
import { useTotalSupply } from '../../data/TotalSupply'
import { ButtonLiquidity } from '../Button'
import { Link } from 'react-router-dom'
import { currencyId } from '../../utils/currencyId'

import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { unwrappedToken } from '../../utils/wrappedCurrency'

import Card from '../Card'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'
import { Dots } from '../swap/styleds'
import Loader from '../Loader'
import intl from 'react-intl-universal'

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

export const HoverCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bg2};
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
  }
`
const TokenElement = styled.div`
  position: relative
  display: flex;
  align-items: center;
  img{
    width: 20px;
    height: auto;
  }
  span{
    margin-left: 26px;
  }
`
interface PositionCardProps {
  pair: Pair
  showUnwrapped?: boolean
  border?: string
}

export function MinimalPositionCard({ pair, showUnwrapped = false, border }: PositionCardProps) {
  const { account } = useActiveWeb3React()

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)
  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined
  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
        ]
      : [undefined, undefined]

  return (
    <>
      {userPoolBalance && (
        <div style={{ color: 'white' }}>
          <div style={{ color: '#B3B3B3', fontSize: 12, marginBottom: 8 }}>Your position</div>
          <AutoColumn>
            <FixedHeightRow onClick={() => setShowMore(!showMore)}>
              <TokenElement>
                <img src={`/images/token/${currency0.symbol}.png`} />
                <img src={`/images/token/${currency1.symbol}.png`} style={{ position: 'absolute', left: 16 }} />
                <span style={{ fontSize: 13 }}>
                  {currency0.symbol}/{currency1.symbol}
                </span>
              </TokenElement>
              <RowFixed>
                <Text fontSize={13} color="#B3B3B3">
                  {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <AutoColumn gap="4px">
              <FixedHeightRow>
                <Text fontSize={13}>Pool share:</Text>
                <RowFixed>
                  <Text color="#B3B3B3" fontSize={13} marginLeft={'6px'}>
                    {poolTokenPercentage ? poolTokenPercentage.toFixed(2) + '%' : '-'}
                  </Text>
                </RowFixed>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={13}>{currency0.symbol}:</Text>
                {token0Deposited ? (
                  <RowFixed>
                    <Text color="#B3B3B3" fontSize={13} marginLeft={'6px'}>
                      {token0Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={13}>{currency1.symbol}:</Text>
                {token1Deposited ? (
                  <RowFixed>
                    <Text color="#B3B3B3" fontSize={13} marginLeft={'6px'}>
                      {token1Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
            </AutoColumn>
          </AutoColumn>
        </div>
      )}
    </>
  )
}

export default function FullPositionCard({ pair, border }: PositionCardProps) {
  const { account } = useActiveWeb3React()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)
  // if (currency0) {
  //   if (currency0.symbol === 'USDT') {
  //     currency0 = unwrappedToken(pair.token1)
  //     currency1 = unwrappedToken(pair.token0)
  //   }
  // }
  const [showMore, setShowMore] = useState(true)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
        ]
      : [undefined, undefined]
  // if (currency0) {
  //   if (unwrappedToken(pair.token0).symbol === 'USDT') {
  //     [token0Deposited, token1Deposited] =
  //       !!pair &&
  //       !!totalPoolTokens &&
  //       !!userPoolBalance &&
  //       JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
  //         ? [
  //             pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
  //             pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false)
  //           ]
  //         : [undefined, undefined]
  //   }
  // }
  return (
    <HoverCard style={{ backgroundColor: '#120D11', border: '1px solid #485053', borderRadius: 8, padding: 16 }}>
      <AutoColumn gap="12px">
        <FixedHeightRow onClick={() => setShowMore(!showMore)} style={{ cursor: 'pointer' }}>
          <RowFixed>
            <Text fontSize={14}>
              {!currency0 || !currency1 ? 
                <Dots style={{ color: 'white' }}>Loading</Dots>
              :
                <TokenElement>
                  <img src={`/images/token/${currency0.symbol}.png`} />
                  <img src={`/images/token/${currency1.symbol}.png`} style={{ position: 'absolute', left: 16 }} />
                  <span>
                    {currency0.symbol}/{currency1.symbol}
                  </span>
                </TokenElement>
              }
            </Text>
          </RowFixed>
          <RowFixed>
            {showMore ? (
              <ChevronUp size="18" color="#F84FA5" style={{ marginLeft: '10px' }} />
            ) : (
              <ChevronDown size="18" color="#F84FA5" style={{ marginLeft: '10px' }} />
            )}
          </RowFixed>
        </FixedHeightRow>
        {showMore && (
          <AutoColumn gap="8px">
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 12, color: '#B3B3B3' }}>
              <div style={{ width: '49%' }}>
                <Text>Pooled{currency0.symbol} :</Text>
                {token0Deposited ? (
                  <RowFixed>
                    <Text marginTop="8px" marginBottom="8px">
                      {token0Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  <Loader />
                )}
                <Text marginBottom="8px">Pool tokens :</Text>
                <Text>{userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}</Text>
              </div>
              <div style={{ width: '47%' }}>
                <Text fontSize={12}>Pooled{currency1.symbol} :</Text>
                {token1Deposited ? (
                  <RowFixed>
                    <Text marginTop="8px" marginBottom="8px">
                      {token1Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  <Loader />
                )}
                <Text marginBottom="8px">Pool share :</Text>
                {poolTokenPercentage ? <Text>{poolTokenPercentage.toFixed(2) + '%'}</Text> : <Loader />}
              </div>
            </div>
            <RowBetween marginTop="10px">
              <ButtonLiquidity as={Link} to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`} width="48%">
                {intl.get('addingLiquidity')}
              </ButtonLiquidity>
              <ButtonLiquidity as={Link} width="48%" to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}>
                {intl.get('removeLiquidity')}
              </ButtonLiquidity>
            </RowBetween>
          </AutoColumn>
        )}
      </AutoColumn>
    </HoverCard>
  )
}
