import React from 'react'
// import { Text } from 'rebass'
// import { ChainId, Currency, currencyEquals, ETHER } from '@uniswap/sdk'
import { ChainId, Currency } from '@uniswap/sdk'
// import styled from 'styled-components'

import { AutoColumn } from '../Column'
// import { AutoRow } from '../Row'
// import CurrencyLogo from '../CurrencyLogo'

// const BaseWrapper = styled.div<{ disable?: boolean }>`
//   border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.bg3)};
//   border-radius: 10px;
//   display: flex;
//   padding: 6px;

//   align-items: center;
//   :hover {
//     cursor: ${({ disable }) => !disable && 'pointer'};
//     background-color: ${({ theme, disable }) => !disable && theme.bg2};
//   }

//   background-color: ${({ theme, disable }) => disable && theme.bg3};
//   opacity: ${({ disable }) => disable && '0.4'};
// `

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency
}: {
  chainId?: ChainId
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  return (
    <AutoColumn gap="md">
      {/* <AutoRow>
        <Text fontWeight={500} fontSize={14}>
          基础币种
        </Text>
      </AutoRow> */}
      {/* <AutoRow gap="4px">
        <BaseWrapper
          onClick={() => {
            if (!selectedCurrency || !currencyEquals(selectedCurrency, ETHER)) {
              onSelect(ETHER)
            }
          }}
          disable={selectedCurrency === ETHER}
        >
          <CurrencyLogo currency={ETHER} style={{ marginRight: 8 }} />
          <Text fontWeight={500} fontSize={16}>
            HT
          </Text>
        </BaseWrapper>
      </AutoRow> */}
    </AutoColumn>
  )
}
